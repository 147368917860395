import React from "react";
import "../Assets/CSS/ContentOne.css";
// import dashboard from "../Assets/Images/dashboard.svg";
import flowImg from "../Assets/Images/flowImg.svg"
import spring from "../Assets/Images/backgroundSprings.svg";

const ContentOne = () => {

  return (
    <div>
      <div className="backContent h-auto pb-[80px]">
        <p
          className="text-white text-[22px] sm:text-[28px] lg:text-[32px] font-poppins tracking-wide capitalize pt-[42px] lg:pt-[50px] px-[15px] sm:px-[20px] xl:px-[80px] block lg:hidden"
        >
          Df-OS Your Factory Co-Pilot
        </p>
        <div className="flex flex-col-reverse lg:grid lg:grid-cols-2 gap-4 pt-[30px] px-[15px] sm:px-[30px] md:px-[50px] 2xl:px-[80px]">
          <div className=" relative z-[10] mt-[0px] lg:mt-[40px]">
            <p
              className="text-white text-[20px] sm:text-[28px] md:text-[32px] tracking-wide lg:text-[36px] font-poppins font-medium capitalize hidden lg:block"
            >
              Df-OS Your Factory Co-Pilot
            </p>
            <ul
              className="text-[#D4D4D4] text-[12px] sm:text-[14px] md:text-[18px] tracking-wide lg:text-[20px] font-poppins leading-[20px] lg:leading-[30px] xl:leading-[36px] list-disc"
            >
              <li className="mb-2">Unified Data: Access all factory data in one environment—your Lighthouse Factory.</li>
              <li className="mb-2">SQDCP: Enhances Safety, Quality, Delivery, Cost, and People for continuous improvement.</li>
              <li className="mb-2">Lightspeed: Accelerate implementation time by 1/12.</li>
              <li className="mb-2">Economy of Scale: Transform at scale with 600+ use cases on a single platform.</li>
            </ul>
          </div>
          <div className="flex justify-center lg:justify-end">
            <img
              src={flowImg}
              alt=""
              className="relative z-[10] w-full"
            />
            <img
              src={spring}
              alt=""
              className="absolute right-[1px] z-[1] hidden lg:block"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContentOne;
