import React from "react";
import "../Assets/CSS/ContentTwo.css";
import oneFact from '../Assets/Images/OneFact.svg'
// import { Link } from "react-router-dom";

const ContentTwo = () => {
  return (
    <div className="tiltBGHomepage">
      <div className="backContTwo pt-[50px]">
        <p className="mobileFactory text-white text-[22px] sm:text-[28px] lg:text-[32px] font-poppins font-medium capitalize block lg:hidden">
          One Factory-One Platform
        </p>
        <div className="flex flex-col-reverse lg:grid lg:grid-cols-2 gap-0 lg:gap-8 px-[15px] sm:px-[30px] md:px-[50px] 2xl:px-[80px] pt-[50px] lg:pt-[100px] pb-[30px] lg:pb-[30px]">
          <div className="mt-[0px] lg:mt-[20px] overlook">
            <p className="text-white text-[28px] md:text-[32px] lg:text-[36px] font-poppins font-medium capitalize tracking-wide lg:block hidden">
              One Factory-One Platform
            </p>
            <ul className="text-[#D4D4D4] text-[12px] sm:text-[14px] md:text-[18px] tracking-wide lg:text-[20px] font-poppins leading-[20px] lg:leading-[30px] xl:leading-[36px] list-disc">
            <li className="mb-2">A no-code, platform designed to build solutions tailored to your specific shop floor needs.</li>
              <li className="mb-2">Connects 600+ Processes Digitally without disrupting your existing setup.</li>
              <li className="mb-2">Helps you become future-ready by aligning with Industry 5.0 standards, transforming your operations into a Lighthouse factory.</li>
            </ul>
            {/* <Link to="/Product">
              <button className="knowMore bg-white text-[#001141] text-[12px] sm:text-[14px] md:text-[18px] p-[10px] rounded-[5px] font-poppins font-medium">
                Know More
              </button>
            </Link> */}
          </div>
          <div className="checkImage flex justify-center lg:justify-end">
            <img
              src={oneFact}
              alt=""
              className="relative bottom-[60px] lg:bottom-[85px] mt-[40px]"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContentTwo;
