import React, { useState } from "react";
import "../Assets/CSS/Testimonies.css";
import prevArrow from "../Assets/Images/PreviousArrow.svg";
import nextArrow from "../Assets/Images/nextArrow.svg";
import inverted from "../Assets/Images/inverted.svg";

const Testimonies = () => {
  const texts = [
    {
      head: "Vinod Mishra",
      orgName: "HUL-ORAI",
      desc: "DesignX is a team of professionals. They always act fast on any issue/support required and the good part is that they update after the issue is resolved. Best of luck for the DesignX team.",
    },
    {
      head: "Shekhar Verma",
      orgName: "Corporate ESG Head, SKH Group ",
      desc: "DesignX's approach to service is exceptional, offering user-friendly software at a highly competitive cost. Their positive, customer-centric approach and excellent communication ensure satisfaction. As ESG Head, I find their ESG module incredibly effective and efficient. I highly recommend DesignX for their innovative and value-driven solutions.",
    },
    {
      head: "Rajeev Thakur",
      orgName: "Unilever India Exports Limited",
      desc: "We have been working with DesignX, from last few years .Thanks to Development & Service team of DesignX for such regular service. It’s good to see that DesignX team makes continuous improvements on the job, post deployment, as per the feedback given.",
    },
    {
      head: " Sunny Singh",
      orgName: "Assistant Manager QA, Ceat Ltd",
      desc: "I am highly satisfied with the services provided by DesignX. The quality of the product is excellent, and it offers great value for money. I would highly recommend DesignX to others. Communication was smooth, and any issues were resolved to my satisfaction.",
    },
    {
      head: "Aakanksha",
      orgName: "Unilever",
      desc: "The team was very supportive and understanding of our needs. They went all out to ensure our standard questionnaire in place. They also provided continuous support for any improvements in the app on an ongoing basis. An overall smooth & satisfying experience!",
    },
    {
      head: "Abhishek Tripathi",
      orgName: "Sr. Executive, Neel Metal Products Limited ",
      desc: "I am pleased with the quality of services and communication from DesignX. The product offers good value for money, and I’m highly likely to recommend their solutions. Overall, the response and service have been commendable.",
    },
    {
      head: "Varun",
      orgName: "Unilever, IT",
      desc: "DesignX has enabled a key SC Collaboration platform for Hindustan Unilever, helping re-imagine the complete process from the perspective of premium user experience and top quality functional experience.",
    },

    {
      head: "Sahil Sharma",
      orgName: "Sr. Maintenance Engineer, HUL",
      desc: "We are highly satisfied with the services provided by DesignX. The quality of the solutions has shown great improvement over time, and the communication has been excellent throughout the project. The overall experience has delivered strong value for money, and we would highly recommend their Df-OS services to others.",
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % texts.length);
  };

  const handlePrevious = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + texts.length) % texts.length
    );
  };
  const handleSlideClick = (index) => {
    setCurrentIndex(index);
  };

  return (
    <div className="testimonies w-full h-auto py-[20px] px-[10px]">
      <div className="flex flex-col lg:grid lg:grid-cols-2 pt-[30px] sm:pt-[0px] pl-[10px] sm:pl-[78px]">
        <div className="w-full lg:w-[60%] mt-[5px] sm:mt-[80px] lg:mt-[200px]">
          <p className="text-gradientTest tracking-wide font-poppins text-[22px] sm:text-[32px] lg:text-[42px] font-semibold">
            What Our Clients Are Saying...
          </p>
          <div className="flex justify-center sm:justify-start">
            <img
              src={prevArrow}
              alt=""
              className="w-[38px] lg:w-[60px] cursor-pointer zoomable"
              onClick={handlePrevious}
            />
            <img
              src={nextArrow}
              alt=""
              className="w-[38px] lg:w-[60px] ml-[32px] cursor-pointer zoomable"
              onClick={handleNext}
            />
          </div>
        </div>
        <div className="mt-[0px] lg:mt-[0px] ml-[0px] lg:ml-[-130px]">
          <img
            src={inverted}
            alt=""
            className="relative top-[100px] hidden sm:block"
          />
          <div className="carousel-container">
            {texts.map((text, index) => {
              const isCurrent = currentIndex === index;
              const transformValue = isCurrent
                ? `translateY(30px)`
                : `translateY(0)`;

              return (
                <div
                  className="slide"
                  key={index}
                  style={{
                    opacity: isCurrent ? 1 : 0.5,
                    filter: isCurrent ? "brightness(1)" : "brightness(0.5)",
                    transform: `translateX(-${
                      currentIndex * 100
                    }%) ${transformValue}`,
                    transition:
                      "transform 0.5s ease-in-out, opacity 0.5s ease-in-out, filter 0.5s ease-in-out",
                  }}
                  onClick={() => handleSlideClick(index)}
                >
                  <div className="p-[10px] rounded">
                    <p className="text-[#009BF8] tracking-wide font-poppins text-[16px] sm:text-[20px] font-semibold">
                      {text.head}
                    </p>
                    <p className="text-[#A8A8A8] tracking-wide font-poppins text-[16px] sm:text-[20px]">
                      {text.orgName}
                    </p>
                    <p className="text-[#ffffff] tracking-wide font-poppins text-[16px] sm:text-[18px]">
                      {text.desc}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonies;
