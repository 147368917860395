import React from 'react'
import unilever from '../Assets/Images/unilever.svg'
import amber from '../Assets/Images/amber.svg'
import dabur from '../Assets/Images/daber.svg'
import toray from '../Assets/Images/torey.svg'
import hero from '../Assets/Images/hero.svg'
import tvs from '../Assets/Images/tvs.svg'

const ClientsTrust = () => {
  return (
    <div className=' mx-[30px] xl:mx-[150px] 2xl:mx-[250px] mt-[40px] pb-[20px]'>
      <div>
        <p className='text-[20px] text-white font-poppins font-medium'><span className='underline underline-offset-8 decoration-[2px]'>Our Clien</span>ts</p>
        <div className='grid grid-cols-2 md:grid-cols-3 gap-4 lg:flex justify-between mt-[20px]'>
            <img src={unilever} alt="" />
            <img src={amber} alt="" />
            <img src={dabur} alt="" />
            <img src={toray} alt=""/>
            <img src={hero} alt="" />
            <img src={tvs} alt="" />
        </div>
      </div>
    </div>
  )
}

export default ClientsTrust
