import React, { useState } from "react";
import avarageTtat from "../Assets/Images/avarageTatt.svg";
import bdReporting from "../Assets/Images/bdReporting.svg";
import esgUseCase from "../Assets/Images/esgUseCases.svg";
import hiraAudit from "../Assets/Images/HiraAudit.svg";
import paperless from "../Assets/Images/paperless.svg";
import preventmain from "../Assets/Images/preventMaintain.svg";
import productionPlanning from "../Assets/Images/productionPlanning.svg";
import OEE from "../Assets/Images/OEE.svg";
import hira_audit from "../Assets/video/HIRA_Audit.mp4";
import "../Assets/CSS/useCases.css";

// Importing @react-pdf-viewer/core
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';

const pdfData = [
  { label: "Average TTAT", image: avarageTtat, pdf: "Average TTAT.pdf" },
  { label: "BD Reporting", image: bdReporting, pdf: "BD Reporting.pdf" },
  { label: "ESG", image: esgUseCase, pdf: "ESG_compressed.pdf" },
  {
    label: "Production Monitoring (OEE)",
    image: hiraAudit,
    pdf: "Production Monitoring.pdf",
  },
  { label: "Paperless Audits", image: paperless, pdf: "Paperless Audits.pdf" },
  {
    label: "Preventive Maintenance",
    image: preventmain,
    pdf: "Preventive Maintenance.pdf",
  },
  {
    label: "Production Planning",
    image: productionPlanning,
    pdf: "Production Planning.pdf",
  },
  { label: "HIRA_Audit.mp4", image: OEE, video: hira_audit },
];

const UseCasesData = () => {
  const [selectedPdf, setSelectedPdf] = useState(null);
  const [selectedVideo, setSelectedVideo] = useState(null);

  // Modified openPdfModal to load PDFs dynamically
  const openPdfModal = async (pdf) => {
    if (typeof pdf === "string") {
      const pdfModule = await import(`../Assets/Pdfs/${pdf}`);
      setSelectedPdf(pdfModule.default);
    } else {
      setSelectedPdf(pdf);
    }
  };

  const closePdfModal = () => {
    setSelectedPdf(null);
  };

  const openVideoModal = (video) => {
    setSelectedVideo(video);
  };

  const closeVideoModal = () => {
    setSelectedVideo(null);
  };

  return (
    <div className="w-full p-10">
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2 border-2 border-dashed border-gray-500 rounded">
        {pdfData.map((item, index) => (
          <div
            key={index}
            className="p-4 md:p-8 lg:p-12 border-b-2 sm:border-r-2 border-dashed border-gray-500 cursor-pointer"
            onClick={() => {
              if (item.video) {
                openVideoModal(item.video);
              } else {
                openPdfModal(item.pdf);
              }
            }}
          >
            <img src={item.image} alt={item.label} />
            <p className="text-xs md:text-sm lg:text-lg text-white font-medium mt-4">
              {item.label}
            </p>
          </div>
        ))}
      </div>

      {/* PDF Modal */}
      {selectedPdf && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
          <div className="bg-white p-[5px] md:p-[20px] w-[95%] md:w-3/4 lg:w-2/3 xl:w-[70%] h-[75%] overflow-auto rounded-lg relative">
            <button
              className="absolute top-2 right-2 z-[100] text-black text-lg"
              onClick={closePdfModal}
            >
              ✖
            </button>
            {/* Replacing react-pdf with @react-pdf-viewer/core */}
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
              <Viewer fileUrl={selectedPdf} />
            </Worker>
          </div>
        </div>
      )}

      {/* Video Modal */}
      {selectedVideo && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
          <div className="bg-white p-[5px] md:p-[20px] w-full md:w-3/4 lg:w-2/3 xl:w-1/2 rounded-lg relative">
            <button
              className="absolute top-2 right-2 z-[100] text-black text-lg"
              onClick={closeVideoModal}
            >
              ✖
            </button>
            <video autoPlay controls className="w-full h-auto">
              <source src={selectedVideo} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      )}
    </div>
  );
};

export default UseCasesData;
